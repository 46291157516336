import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function WinterOpt()
{
  const id = "winteropt";

  const thumbnail = "projects/winteropt/winteropt-thumbnail.jpeg";

  const title = "Winter optimization";

  const company = "EPITA";

  const shortDescription = "Project to find a cost-effective path for snowplows to clear snow in Montréal.";

  const description = "This research project aims to address the concerns of Montréal residents regarding " +
                      "snowplowing operations while considering the delicate issue of increasing the allocated " +
                      "funds. The objective is to minimize the cost of a typical snowplowing day by finding an " + 
                      "efficient and cost-effective path for snowplows around the Montréal road network. Additionally, " + 
                      "a drone-based aerial analysis will be performed to identify the sectors requiring immediate snowplowing. " +
                      "The report provides a summary of the data used, the study perimeter, hypotheses, model choices, selected solutions, "+ 
                      "cost model for snow removal operations, and limitations of the proposed model.";

  const sourceCode = "https://github.com/barboraplasovska/winter-optimization-problem";

  const technologies = "Python, Jupyter notebook";

  const maintechno = ProjectType.Python;

  const pictures = [
                    "projects/winteropt/winteropt1.png",
                    "projects/winteropt/winteropt2.png",
                    "projects/winteropt/winteropt3.png"
                    ];

  const authors = [
                {
                  name: "Barbora Plašovská (project leader)",
                  link: null
                },
                {
                    name: "Enrique Tarabay",
                    link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
                  },
                  {
                    name: "Elie Abi Khalil",
                    link: "https://www.linkedin.com/in/elie-abi-khalil-0a1636205/"
                  },
                 {
                    name: "Elise Baturone",
                    link: "https://www.linkedin.com/in/elise-baturone/"
                 },
                 
  ];

  const dates = [
      "April 2023",
      "June 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}