import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from "@mui/material";
import theme from "../../styles/theme";
import ProjectNavBar from '../../components/NavBar/ProjectNavBar';
import Contact from '../../pages/Contact/Contact';

const PrivacyPolicy = ({ 
  projectName = "Project Name",
  sections = [], 
  contactEmail = "support@example.com", 
  effectiveDate = "2024-01-01" 
}) => {
  return (
    <>
      {/* NavBar Component */}
      <ProjectNavBar projectName={projectName} />

        <section id="privacy-policy" theme={theme} style={{ paddingTop: '30px' }}>
        <Box padding='40px'>
            <Typography variant="h3" paddingBottom='20px'>
            Privacy policy
            </Typography>
            {sections.map((section, index) => (
            <Box key={index} paddingBottom="20px">
                <Typography variant="h6" style={{ fontWeight: "bold", paddingTop: '10px' }}>
                {section.title}
                </Typography>
                <Typography>
                {section.content}
                </Typography>
            </Box>
            ))}
            <Typography variant="h6" style={{ fontWeight: "bold", paddingTop: '10px' }}>
            Effective Date
            </Typography>
            <Typography>
            This privacy policy is effective as of {effectiveDate}.
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "bold", paddingTop: '10px' }}>
            Contact Us
            </Typography>
            <Typography>
            If you have any questions regarding privacy or have questions about our practices, please contact us via email at {contactEmail}.
            </Typography>
        </Box>
        </section>

    {/* Footer */}
    <Contact />
    </>
  );
};

PrivacyPolicy.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  contactEmail: PropTypes.string,
  effectiveDate: PropTypes.string,
};

export default PrivacyPolicy;
