import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function RickAndMorty()
{
  const id = "rickandmorty";

  const thumbnail = "projects/rickandmorty/rickandmorty-thumbnail.png";

  const title = "Rick and Morty";

  const company = "Shadow";

  const shortDescription = "Simple android application made using the Rick and Morty API.";

  const description = "This was a first little project I made to get familiar with Jetpack Compose " +
                      "while I was an intern at Shadow. It's a simple application that uses the Rick and Morty API " +
                      "to display the characters, episodes and locations of the show. It was a fun little project.";

  const technologies = "Kotlin, Jetpack Compose";

  const maintechno = ProjectType.Android;

  const pictures = [
    "projects/rickandmorty/rickandmorty1.png",
    "projects/rickandmorty/rickandmorty2.png",
    "projects/rickandmorty/rickandmorty3.png",
    ];

  const sourceCode = "https://github.com/barboraplasovska/rickandmorty";

  const appStoreLink = null;

  const authors = null;

  const dates = [
      "September 4 2023",
      "September 11 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}