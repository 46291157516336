import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Minimake()
{
  const id = "minimake";

  const thumbnail = "projects/minimake/minimake-thumbnail.png";

  const title = "Minimake";

  const company = "EPITA";

  const shortDescription = "A simplified version of the classic make(1) from the Unix world. "

  const description = "The goal of the Minimake project was to program a simplified version of the classic make(1) from the" +
                      "Unix world. make is a tool allowing to build files from dependencies and it does not" +
                      "rebuild files which are more recent than their dependencies.\n\n" +
                      "Make is a tool that has many features, some of them being complex to implement. Because of that," +
                      "Minimake only recognizes a subset of the syntax and functionalities of a classic makefile. However," +
                      "Minimake dives into every essential feature of make.";

  const technologies = "C";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = null;

  const dates = [
      "October 17, 2022",
      "October 22, 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}