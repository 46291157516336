import React from 'react';
import GenericMarketingPage from '../../../components/Apps/GenericMarketingPage';

const StitchCounterMarketingPage = () => {
  return (
    <GenericMarketingPage
      projectName="Stitch Counter"
      tagline="Never lose your count again!"
      description={[
        "Stitch Counter is a versatile app designed for knitting, crocheting, and any other counting needs. Whether you're keeping track of stitches in your latest project or counting inventory in your business, Stitch Counter makes it easy and convenient.",
        "Keep track of your stitches or rows in your latest crafting projects using this ad-free Stitch Counter app. Whether you're a hobbyist or a professional, Stitch Counter helps you stay focused on your creations without distractions.",
        "Now available on iPad! Experience a seamless counting experience with the convenience of a larger screen, making crafting projects even easier to manage."
      ]}
      appStoreLink="https://apps.apple.com/fr/app/stitchcounterapp/id6480349416"
      screenshotPath="/projects/stitchcounter/stitchcounter-phone-screenshot.png"
      downloadButtonText="Download Stitch Counter"
      features={[
        { 
          title: "Versatile Counting", 
          description: "Count stitches, rows, or anything else. Ideal for knitting, crocheting, or general counting needs, Stitch Counter adapts to all your projects." 
        },
        { 
          title: "Ad-Free Experience", 
          description: "Enjoy an uninterrupted, distraction-free experience with no ads or pop-ups, so you can stay focused on your craft." 
        },
        { 
          title: "iPad Compatibility", 
          description: "Use Stitch Counter on your iPad for a larger, more convenient interface that makes managing complex projects simple." 
        },
      ]}
      whyDescription={`Stitch Counter is built for crafters, by crafters. Whether you're a knitter, crocheter, or someone who just needs a reliable counting tool, Stitch Counter is designed to make your projects easier and more enjoyable. 
        With its intuitive interface and ad-free experience, you can focus entirely on your creativity without distractions. Perfect for crafting projects, inventory counting, or any task where precision and reliability are key.`}
    />
  );
};

export default StitchCounterMarketingPage;
