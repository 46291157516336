import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function FortyTwoSh()
{
  const id = "42sh";

  const thumbnail = "projects/42sh/42sh-thumbnail.png";

  const title = "42sh";

  const company = "EPITA";

  const shortDescription = "This project consisted of recreating a POSIX compliant shell.";

  const description = "This project consisted of recreating a POSIX compliant shell written in C. " + 
                      "Implementation of scripting, binary operators and multiple builtin commands such as " +
                      "echo, cat, cd and others.";

  const technologies = "C, POSIX Shell";

  const maintechno = ProjectType.C;

  const pictures = null;

  const appStoreLink = null;
  
  const authors = [
                {
                  name: "Barbora Plašovská (project leader)",
                  link: null
                },
                {
                  name: "Enrique Tarabay",
                  link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
                },
                {
                  name: "Elise Baturone",
                  link: "https://www.linkedin.com/in/elise-baturone/"
                },
                {
                  name: "Elsa Keirouz",
                  link: "https://www.linkedin.com/in/elsa-keirouz-09a6061ab/"
                }
  ];

  const dates = [
      "January 1, 2023",
      "January 31, 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        appStoreLink={appStoreLink}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}