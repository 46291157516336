import React from 'react';
import { Box} from "@mui/material";
import theme from "../styles/theme";

import NavBar from '../components/NavBar/ProjectNavBar.js';

const SupportMePage = () => {
  return (
    <>
    <NavBar/>
     <section 
        id="supportme"
            theme={theme}
            style={{
                     paddingTop: '30px'
                  }}
                     >
     <Box padding='40px'>
        <div className="kofi-widget-container">
        <iframe
            id='kofiframe'
            src='https://ko-fi.com/barboraplasovska/?hidefeed=true&widget=true&embed=true&preview=true'
            style={{ border: 'none', width: '100%', padding: '0px'}}
            height='712'
            title='barboraplasovska'
        ></iframe>
        <style jsx>{`
            .kofi-widget-container {
            max-width: 600px;
            margin: 0 auto;
            align-content: left;
            }
        `}</style>
        </div>
    </Box>
    </section>
    </>
  );
};

export default SupportMePage;
