import { Box, Typography,Grid } from "@mui/material";
import React from 'react';
import theme from "../../styles/theme";

// School projects
import Tiger from './SchoolProjects/Tiger';
import JWS from './SchoolProjects/JWS';
import FortyTwoSh from './SchoolProjects/42sh';
import Httpd from './SchoolProjects/Httpd';
import Malloc from './SchoolProjects/Malloc';
import Minimake from "./SchoolProjects/Minimake";
import Evalexpr from "./SchoolProjects/Evalexpr";
import Tinyprintf from "./SchoolProjects/Tinyprintf";
import Bshell from "./SchoolProjects/Bshell";
import OCRSudoku from "./SchoolProjects/OCRSudoku";
import BitByBit from "./SchoolProjects/BitByBit";
import WinterOpt from "./SchoolProjects/WinterOpt";
import Jaguar from "./SchoolProjects/Jaguar";
import GoodSprout from "./SchoolProjects/GoodSprout";
import Sunny from "./SchoolProjects/Sunny";
import RickAndMorty from "./SchoolProjects/RickAndMorty";

// Personal projects
import StitchCounter from "./PersonalProjects/StitchCounter";
import BeeBetter from "./PersonalProjects/BeeBetter";
import TrackMyShoe from "./PersonalProjects/TrackMyShoe";
import StudyBuddies from "./SchoolProjects/StudyBuddies";

function Portfolio()
{
    return (
      <section 
          id="portfolio"
          theme={theme}
          style={{ backgroundColor:theme.palette.background.primary,
                
                  paddingTop: '30px'
                }}
                  >
        <Box padding='40px'>
            <Typography
                variant="h3"
                paddingBottom='20px'>
                  Portfolio
              </Typography>
              <Typography
                variant="h5"
                paddingBottom='10px'
                style={{fontWeight:"bold"}}>
                  PERSONAL PROJECTS
              </Typography>
                <Grid container spacing={4} columns={{ xs: 3, sm: 9, md: 12 }}>
                    <Grid item xs={3}>
                      <TrackMyShoe/>
                    </Grid>
                    <Grid item xs={3}>
                      <BeeBetter/>
                    </Grid>
                    <Grid item xs={3}>
                      <StitchCounter/>
                    </Grid>
            </Grid>
              <Typography
                variant="h5"
                paddingTop='20px'
                paddingBottom='10px'
                style={{fontWeight:"bold"}}>
                  SCHOOL PROJECTS
              </Typography>
              <Grid container spacing={4} columns={{ xs: 3, sm: 9, md: 12 }}>
                <Grid item xs={3}>
                  <StudyBuddies/>
                </Grid>
                  <Grid item xs={3}>
                    <RickAndMorty/>
                  </Grid>
                  <Grid item xs={3}>
                    <Sunny/>
                  </Grid>
                  <Grid item xs={3}>
                    <Jaguar/>
                  </Grid>
                  <Grid item xs={3}>
                    <WinterOpt/>
                  </Grid>
                  <Grid item xs={3}>
                    <Tiger/>
                  </Grid>
                  <Grid item xs={3}>
                    <JWS/>
                  </Grid>
                  <Grid item xs={3}>
                    <FortyTwoSh/>
                  </Grid>
                  <Grid item xs={3}>
                    <Httpd/>
                  </Grid>
                  <Grid item xs={3}>
                    <Malloc/>
                  </Grid>
                  <Grid item xs={3}>
                    <Minimake/>
                  </Grid>
                  <Grid item xs={3}>
                    <Evalexpr/>
                  </Grid>
                  <Grid item xs={3}>
                    <Tinyprintf/>
                  </Grid>
                  <Grid item xs={3}>
                    <Bshell/>
                  </Grid>
                  <Grid item xs={3}>
                    <OCRSudoku/>
                  </Grid>
                  <Grid item xs={3}>
                    <BitByBit/>
                  </Grid>
                  <Grid item xs={3}>
                    <GoodSprout/>
                  </Grid>
          </Grid>
        </Box>
      </section>
    );
}

export default Portfolio;