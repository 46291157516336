import { Box, Typography, Grid, Link , Button} from "@mui/material";
import React from 'react';
import theme from "../../styles/theme";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';

import { Link as RouterLink } from 'react-router-dom';

function Contact()
{

    const today = new Date();
    const year = today.getFullYear();

    return (
     <section 
     id="contact"
     theme={theme}
     style={{ backgroundColor: "#111111",
             paddingTop: '30px',
             paddingBottom: '10px'
           }}
             >
   <Box padding='40px'>
    <Grid container direction="row" spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={4} md={9}>
          <Typography
              variant="h3"
              paddingBottom='20px'
              color="#ffffff">
                Contact me
            </Typography>
            <Typography
              color= '#ffffff'
              paddingBottom='20px'>
              Get in touch with me via social media or email.
            </Typography>
            <Grid container director="row" spacing={3} columns={{xs: 4}}>
              <Grid item xs={4}>
                <Link href="https://www.linkedin.com/in/barboraplasovska" target="_blank" style={{textDecoration: 'none'}}>
                  <Grid container direction="row" spacing={1} alignItems="center" sx={{color: '#ffffff', fontStyle: 'normal'}}>
                    <Grid item>
                      <LinkedInIcon />
                    </Grid>
                    <Grid item>
                      <Typography>LinkedIn</Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link href="mailto:bplasovska@gmail.com" target="_blank" style={{textDecoration: 'none'}}>
                  <Grid container direction="row" spacing={1} alignItems="center" sx={{color: '#ffffff', fontStyle: 'normal'}}>
                    <Grid item>
                      <EmailIcon />
                    </Grid>
                    <Grid item>
                      <Typography>bplasovska@gmail.com</Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link href="https://www.github.com/barboraplasovska" target="_blank" style={{textDecoration: 'none'}}>
                  <Grid container direction="row" spacing={1} alignItems="center" sx={{color: '#ffffff', fontStyle: 'normal'}}>
                    <Grid item>
                      <GitHubIcon />
                    </Grid>
                    <Grid item>
                      <Typography>Github</Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
        </Grid>
        <Grid item alignContent="center">
            <RouterLink to="/supportme" style={{textDecoration: 'none'}}>
              <Button>
              <img height='36' style={{ border: '0px', height: '80px' }} src={process.env.PUBLIC_URL + '/support-me-kofi.png'} border='0' alt='Buy Me a Coffee at ko-fi.com' />
              </Button>
            </RouterLink>
        </Grid>
      </Grid>
   </Box>
    <Typography
      textAlign='center'
      sx={{
            color: 'gray'
          }}>
    © {year} Barbora Plašovská. All Rights Reserved.
    </Typography>
 </section>
    );
}

export default Contact;
