import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function StitchCounter()
{
  const id = "stitchcounter";

  const thumbnail = "projects/stitchcounter/stitchcounter-thumbnail.png";

  const title = "Stitch Counter";

  const company = "";

  const shortDescription = "iOS native application to count stitches/rows in knitting, made using SwiftUI and SwiftData."

  const description = "Stitch Counter is a versatile app designed for knitting, crocheting, and any other counting needs. " + 
                      "This app was made using SwiftUI and SwiftData.";

  const technologies = "SwiftUI, SwiftData";

  const maintechno = ProjectType.iOS;

  const sourceCode = null;

  const appStoreLink = "https://apps.apple.com/app/id6480349416";

  const pictures = [
    "projects/stitchcounter/stitchcounter1.png",
    "projects/stitchcounter/stitchcounter2.png",
    "projects/stitchcounter/stitchcounter3.png",
    "projects/stitchcounter/stitchcounter4.png",
    "projects/stitchcounter/stitchcounter5.png",
    "projects/stitchcounter/stitchcounter6.png",
    "projects/stitchcounter/stitchcounter7.png",
  ];

  const authors = null;

  const dates = null;


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}