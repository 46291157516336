export const ProjectType = {
    iOS: { name: "Swift", color: "#FFA500", darkColor: "#FFA500" }, // Orange
    Android: { name: "Kotlin", color: "#008000", darkColor: "#008000" }, // Green
    Flutter: { name: "Flutter", color: "#ADD8E6", darkColor: "#4682B4" }, // Light Blue
    C: { name: "C", color: "#00008B", darkColor: "#00008B" }, // Dark Blue
    CPP: { name: "C++", color: "#FF69B4", darkColor: "#FF69B4" }, // Pink
    Java: { name: "Java", color: "#FF0000", darkColor: "#FF0000" }, // Red
    CSharp: { name: "C#", color: "#800080", darkColor: "#800080" }, // Purple
    Python: { name: "Python", color: "#FFDE5C", darkColor: "#f5c400" }, // Yellow
};
