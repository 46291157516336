import { Box, AppBar, Toolbar, Button } from "@mui/material";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';


function NotFoundNavBar()
{
    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
            <Toolbar variant="regular"> 
                <Box sx={{  display: 'flex', alignItems: 'center', flex: 1 }}>
                    <RouterLink to="/" style={{textDecoration: 'none'}}>
                        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" height="50px"/>
                    </RouterLink>
                </Box>
                    
                <Box sx={{ marginLeft: 2 }}>
                <RouterLink to="/" style={{ textDecoration: 'none' }}>
                    <Button sx={{ color: '#ffffff', textTransform: 'none', fontSize: '18px' }}>
                    Back to website
                    </Button>
                </RouterLink>
                </Box>
            </Toolbar>
        </AppBar>
        </Box>
    );
}

export default NotFoundNavBar;