import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function BitByBit()
{
  const id = "bitbybit";

  const thumbnail = "projects/bitbybit/bitbybit-thumbnail.png";

  const title = "Bit by Bit";

  const company = "EPITA";

  const shortDescription = "An educational game-like application to aid EPITA students with OCAML.";

  const description = "Bit by Bit is an educational application to aid fresh new Epita students in their thirst for " + 
                      "knowledge (and good grades) in the programming language OCAML. It is available in both English " + 
                      "and French as to not practice any sort of favoritism. Bit by Bit will help every single student " +
                      "who needs it. Whether they are struggling from the beginning, trying to understand the fundamentals " +
                      "and want to learn the theory, or simply wish to practise and deepen their skills.";

  const sourceCode = null;

  const technologies = "C#, Firebase";

  const maintechno = ProjectType.CSharp;

  const pictures = [
                    "projects/bitbybit/bitbybit1.png",
                    "projects/bitbybit/bitbybit2.png",
                    "projects/bitbybit/bitbybit3.png"
                    ];

  const authors = [
                {
                  name: "Barbora Plašovská (project leader)",
                  link: null
                },
                {
                  name: "Elise Baturone",
                  link: "https://www.linkedin.com/in/elise-baturone/"
                },
                {
                  name: "Elsa Keirouz",
                  link: "https://www.linkedin.com/in/elsa-keirouz-09a6061ab/"
                },
                {
                  name: "Emile Preumont",
                  link: null
                }
  ];

  const dates = [
      "September 2021",
      "December 2021"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}