import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    font:{
      title: 'Droid Sans'
    },
    palette: {
      primary: {
        main: '#111111', // black
      },
      secondary: {
        main: '#972C2D' // pink
      },
      background: {
        primary: '#f8f8f8', // white
      },
      text: {
        primary: '#746D69' // white
      },
      
    },
  });

export default theme;