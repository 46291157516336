import React from 'react';
import GenericMarketingPage from '../../../components/Apps/GenericMarketingPage';

const BeeBetterMarketingPage = () => {
  return (
    <GenericMarketingPage
      projectName="BeeBetter"
      tagline="Your personal habit tracker"
      description={[
        "BeeBetter is your ultimate companion for building and tracking healthy habits.",
        "Whether you're looking to establish a daily exercise routine, improve your focus, or build better productivity habits, BeeBetter empowers you to take control of your daily life.",
        "Our app is designed to make habit tracking seamless and enjoyable. You can create personalized habit lists, set reminders, and review your progress with beautifully designed visualizations.",
        "Unlike other habit trackers, BeeBetter prioritizes your privacy and simplicity. All your data is securely stored locally, and there are no ads to distract you from reaching your goals.",
        "Whether you're just getting started with habit-building or you're a seasoned goal-setter, BeeBetter makes it easy to stay consistent and motivated.",
      ]}
      appStoreLink="https://apps.apple.com/app/beebetter-habit-tracker/id6502307654"
      screenshotPath="/projects/beebetter/beebetter-phone-screenshot.png"
      downloadButtonText="Download BeeBetter"
      features={[
        { title: "Simple Habit Tracking", description: "Easily create and track your daily habits. Whether it's exercise, meditation, water intake, or productivity, BeeBetter has you covered." },
        { title: "Visual Progress", description: "Visualize your progress with insightful charts and graphs. Track your habits daily, weekly, and monthly to stay motivated." },
        { title: "Customizable Notifications", description: "Stay on track with reminders and notifications for your habits. Customize them to suit your schedule and keep your goals in focus." },
      ]}
      whyDescription={`BeeBetter helps you create lasting habits that will improve your life. Whether you want to boost your fitness, focus on personal growth, or enhance your productivity, BeeBetter makes it easy to track and stay motivated.
        With intuitive tracking and progress visualization, you'll always know where you stand and what's next.`}
    />
  );
};

export default BeeBetterMarketingPage;
