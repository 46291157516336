import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function TrackMyShoe() {
  const id = "trackmyshoe";

  const thumbnail = "projects/trackmyshoe/trackmyshoe-thumbnail.png";

  const title = "Track My Shoe";

  const company = "";

  const shortDescription = "iOS app that syncs your workouts from HealthKit to track your shoe mileage.";

  const description = "Track My Shoe is an intuitive app designed to help runners and walkers track the mileage of their shoes, ensuring they are replaced at the optimal time to avoid injury. " +
                      "The app syncs workouts from the Health app, allowing users to assign shoes to their activities and monitor the mileage on each pair. " +
                      "It also values privacy, storing all data locally on the device or iCloud, ensuring user data is secure and private. There is no tracking of user data externally.";

  const technologies = "SwiftUI, HealthKit, Core Data";

  const maintechno = ProjectType.iOS;

  const sourceCode = null;

  const appStoreLink = "https://apps.apple.com/app/id6738585430";

  const pictures = [
    "projects/trackmyshoe/trackmyshoe1.png",
    "projects/trackmyshoe/trackmyshoe2.png",
    "projects/trackmyshoe/trackmyshoe3.png",
    "projects/trackmyshoe/trackmyshoe4.png",
  ];

  const authors = null;

  const dates = null;

  return (
    <ProjectCard
      id={id}
      thumbnail={thumbnail}
      maintechno={maintechno}
      title={title}
      company={company}
      shortDescription={shortDescription}
      description={description}
      sourceCode={sourceCode}
      appStoreLink={appStoreLink}
      technologies={technologies}
      pictures={pictures}
      authors={authors}
      dates={dates}
    />
  );
}
