import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, IconButton, Button } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from 'react-router-dom';
import AnchorLink from "react-anchor-link-smooth-scroll";
import theme from "../../styles/theme";

export default function DrawerComponent({ links, linkType = 'router' }) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const renderLink = (link) => {
    // Render RouterLink if linkType is 'router', otherwise use AnchorLink
    if (linkType === 'router') {
      return (
        <RouterLink to={link.href} style={{ textDecoration: 'none' }}>
          <Button sx={{ color: '#111111', textTransform: 'none', fontSize: '18px' }}>
            {link.label}
          </Button>
        </RouterLink>
      );
    } else {
      return (
        <AnchorLink href={link.href} style={{ textDecoration: 'none' }}>
          <Button sx={{ color: '#111111', textTransform: 'none', fontSize: '18px' }}>
            {link.label}
          </Button>
        </AnchorLink>
      );
    }
  };

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {links.map((link, index) => (
            <ListItem button key={index} onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                {renderLink(link)}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <IconButton
        color={theme.palette.background.primary}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>
    </>
  );
}
