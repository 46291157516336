import '../styles/App.css';
import 'typeface-roboto';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/theme.js'

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home';
import SupportMePage from './SupportMePage.js';
import NotFoundPage from './NotFoundPage.js';

// Stitch Counter
import SCMarketingPage from './Apps/StitchCounter/MarketingPage.js';
import SCPrivacyPolicy from './Apps/StitchCounter/PrivacyPolicy.js';
import SCSupportPage from './Apps/StitchCounter/SupportPage.js';

// Bee Better
import BBMarketingPage from './Apps/BeeBetter/MarketingPage.js';
import BBPrivacyPolicy from './Apps/BeeBetter/PrivacyPolicy.js';
import BBSupportPage from './Apps/BeeBetter/SupportPage.js';

// Track My Shoe
import TMSMarketingPage from './Apps/TrackMyShoe/MarketingPage.js';
import TMSPrivacyPolicy from './Apps/TrackMyShoe/PrivacyPolicy.js';
import TMSSupportPage from './Apps/TrackMyShoe/SupportPage.js';

export default  function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
      <ThemeProvider theme={theme}>
      <Routes>
          <Route path="*" element={ <NotFoundPage />} />
          <Route path="/" element={ <Home/> } />

          <Route path="/supportme" element= { <SupportMePage/> } />

          <Route path="/stitchcounter" element= { <SCMarketingPage/> } />
          <Route path="/stitchcounter/privacy-policy" element= { <SCPrivacyPolicy/> } />
          <Route path="/stitchcounter/support" element= { <SCSupportPage/> } /> 

          <Route path="/beebetter" element= { <BBMarketingPage/> } />
          <Route path="/beebetter/privacy-policy" element= { <BBPrivacyPolicy/> } />
          <Route path="/beebetter/support" element= { <BBSupportPage/> } />
          <Route path="/beebetter/support-page" element= { <BBSupportPage/> } /> { /* FIXME: remove link */ }

          <Route path="/trackmyshoe" element={ <TMSMarketingPage/>} />
          <Route path="/trackmyshoe/privacy-policy" element={ <TMSPrivacyPolicy/>} />
          <Route path="/trackmyshoe/support" element={ <TMSSupportPage/>} />
      </Routes>
      </ThemeProvider>
    </BrowserRouter>
    </React.Fragment>
  );
}