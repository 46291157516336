import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Sunny()
{
  const id = "sunny";

  const thumbnail = "projects/sunny/sunny-thumbnail.png";

  const title = "Sunny";

  const company = "";

  const shortDescription = "Sunny is a simple cross-platform mobile weather application.";

  const description = "Sunny is a simple weather application. By default, it asks the user permission for their " 
                       + "current location to show the Weather at their current position. The user can add other places " + 
                        "and delete them as they likes. Features include hourly temperature for the next 24 hours, daily " + 
                        "highest and lowest temperatures for the next 10 days, as well as feel like temperature, humidity, wind " + 
                        "direction and speed and visibility.";

  const technologies = "Dart, Flutter";

  const maintechno = ProjectType.Flutter

  const pictures = [
    "projects/sunny/sunny1.png",
    "projects/sunny/sunny2.png",
    "projects/sunny/sunny3.png",
    "projects/sunny/sunny4.png",
    "projects/sunny/sunny5.png",
    ];

  const sourceCode = "https://github.com/barboraplasovska/sunny";

  const appStoreLink = null;

  const authors = null;

  const dates = [
      "July 7 2023",
      "July 10 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}
