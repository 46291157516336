import React from 'react';
import SupportPageComponent from '../../../components/Apps/GenericSupportPage';

const BeeBetterSupportPage = () => {
  return (
      <SupportPageComponent
        projectName="BeeBetter"
        title="Support for BeeBetter"
        description="BeeBetter is your personal habit tracker! Whether you're trying to build new habits or break old ones, BeeBetter makes it easy and fun. With a simple and intuitive interface, you can track your progress and stay motivated to reach your goals."
        imageUrl={process.env.PUBLIC_URL + "/projects/beebetter/beebetter-thumbnail.png"}
      />
  );
};

export default BeeBetterSupportPage;
