import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function OCRSudoku()
{
  const id = "ocrsudoku";

  const thumbnail = "projects/ocrsudoku/ocrsudoku-thumbnail.jpeg";

  const title = "OCR Sudoku";

  const company = "EPITA";

  const shortDescription = "Implementation of an application that solves a sudoku from an image.";

  const description = "It was a project to solve a sudoku from an image. The app takes an image, processes it, " + 
                      "detects the grid and the grid cells. Then using a neural network that I personally implemented, " + 
                      "it will recognise the digit and finally return the solved grid.";

  const sourceCode = "https://github.com/barboraplasovska/ocr_sudoku";

  const technologies = "C";

  const maintechno = ProjectType.C;

  const pictures = [
                "projects/ocrsudoku/ocrsudoku1.png",
                "projects/ocrsudoku/ocrsudoku2.png",
                "projects/ocrsudoku/ocrsudoku3.png",
                "projects/ocrsudoku/ocrsudoku4.png",
                "projects/ocrsudoku/ocrsudoku5.png",
  ];

  const authors = [
    {
      name: "Barbora Plašovská (project leader)",
      link: null
    },
    {
      name: "Enrique Tarabay",
      link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
    },
    {
      name: "Alexandre Bailly",
      link: "https://www.linkedin.com/in/alexandre-bailly-01baab267/"
    },
    {
      name: "Elsa Keirouz",
      link: "https://www.linkedin.com/in/elsa-keirouz-09a6061ab/"
    },
  ];

  const dates = [
      "September 2021",
      "December 2021"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}