import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function GoodSprout()
{
  const id = "goodsprout";

  const thumbnail = "projects/goodsprout/goodsprout-thumbnail.png";

  const title = "GoodSprout";

  const company = "Canadian start-up";

  const shortDescription = "A mobile application to take care of plants."

  const description = "GoodSprout is an application designed to take care of user's plants. " + 
                        "The user can create their plant, save how frequently they want to water " +
                        "them and the app should sent then reminders to water their plants. (Unfinished)";

  const technologies = "Flutter, Dart, MySQLite";

  const maintechno = ProjectType.Flutter;

  const sourceCode = "https://github.com/barboraplasovska/GoodSprout";

  const appStoreLink = null;

  const pictures = null;

  const authors = null;

  const dates = [
      "June 2020",
      "September 2020"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}