import React from 'react';
import SupportPageComponent from '../../../components/Apps/GenericSupportPage';

const StitchCounterSupportPage = () => {
  return (
      <SupportPageComponent
        projectName="Stitch Counter"
        title="Support for Stitch Counter"
        description="Stitch Counter is a versatile app designed for knitting, crocheting, and any other counting needs. Whether you're keeping track of stitches in your latest project or counting inventory in your business, Stitch Counter makes it easy and convenient. Enjoy an uninterrupted experience with no ads or distractions."
        imageUrl={process.env.PUBLIC_URL + "/projects/stitchcounter/stitchcounter-thumbnail.png"}
      />
  );
};

export default StitchCounterSupportPage;
