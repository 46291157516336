import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function JWS()
{
  const id = "jws";

  const thumbnail = "projects/jws/jws-thumbnail.png";

  const title = "Java Web Services";

  const company = "EPITA";

  const shortDescription = "Rest API in Java for a video game called Bomberman using a layered architecture."

  const description = "Bomberman is a video game where the player must defeat enemies by placing bombs that" + 
                      "explode in cardinal directions after a delay to destroy obstacles and kill enemies. Players" +
                      "can also be killed if they are caught in a bomb explosion, including their own." +
                      "\n\n" +
                      "The goal of this project was to create the backend of an online Bomberman game using REST" +
                      "endpoints for the client to communicate with the backend.";

  const technologies = "Java, PostgreSQL, Quarkus, Hibernate";

  const maintechno = ProjectType.Java;

  const pictures = null;

  const authors = null;

  const dates = [
      "February 13, 2023",
      "February 18, 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}