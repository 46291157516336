import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function StudyBuddies()
{
  const id = "studybuddies";

  const thumbnail = "projects/studybuddies/studybuddies-thumbnail.png";

  const title = "StudyBuddies";

  const company = "EPITA";

  const shortDescription = "A mobile application to connect students and help them study together."

  const description = "StudyBuddies is a mobile application designed to connect students and help them study together. " +
                        "The user can create a profile, add their classes and find other students to study with. " +
                        "The app also includes a chat feature to communicate with other students." +
                        "Users can organise events, create exams and visualize them in their calendar." + 
                        "I worked primarly on the front-end of the application, using Flutter and Dart.";
                
  const technologies = "Flutter, Dart, MariaDB, Docker, Express, Socket.io";

  const maintechno = ProjectType.Flutter;

  const sourceCode = "https://github.com/barboraplasovska/studybuddies";

  const appStoreLink = null;

  const pictures = [
    "projects/studybuddies/studybuddies1.png",
    "projects/studybuddies/studybuddies2.png",
    "projects/studybuddies/studybuddies3.png",
    "projects/studybuddies/studybuddies4.png",
  ];

  const authors = [
    {
        name: "Barbora Plašovská",
        link: null
      },
      {
        name: "Nathan Bonelli",
        link: "https://www.linkedin.com/in/nathan-bonelli/"
      },
      {
        name: "Paul Genillon",
        link: "https://www.linkedin.com/in/paul-genillon/"
      },
      {
        name: "Emeric Dissez",
        link: "https://www.linkedin.com/in/emeric-dissez/"
      },
  ];

  const dates = [
      "February 2024",
      "December 2024"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}