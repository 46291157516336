import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Bshell()
{
  const id = "Bshell";

  const thumbnail = "projects/bshell/bshell-thumbnail.png";

  const title = "Bshell";

  const company = "EPITA";

  const shortDescription = "Implementation of a basic shell with our own parser and builtin commands.";

  const description = "Bshell is a basic shell, completely implemented in C, has it's own parser and " +
                      "as well as the builtin commands like (cd, ls, cat, ect.. ), it also includes " +
                      "other commands such as animations.\n\n" +
                      "We also implemented operators such as pipes, logical and (&&) and logical or (||). " +
                      "To see the complete list of available commands and the syntax, please refer to the README" +
                      " in the git repository."

  const sourceCode = "https://github.com/barboraplasovska/bshell";

  const technologies = "C, Shell";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = [
                {
                  name: "Barbora Plašovská (project leader)",
                  link: null
                },
                {
                  name: "Enrique Tarabay",
                  link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
                },
                {
                  name: "Charbel Abboud",
                  link: null
                }
  ];

  const dates = [
      "January 2022",
      "May 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}