import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import theme from "../../styles/theme";
import { useMediaQuery } from 'react-responsive';
import ProjectNavBar from '../NavBar/ProjectNavBar';
import Contact from '../../pages/Contact/Contact';

const SupportPageComponent = ({ projectName, title, description, imageUrl, children }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  return (
    <>
    <ProjectNavBar projectName={projectName}/>
    <section id="supportpage" theme={theme} style={{ paddingTop: '30px' }}>
      <Box padding='40px'>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          {!isMobile ?
            <>
              <Grid item xs={3} sm={5} md={8}>
                <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                  {title}
                </Typography>
                <Typography>{description}</Typography>
                <Typography>For any support or inquiries, please feel free to reach out to us.</Typography>
                {children}
              </Grid>
              <Grid item xs={1} sm={3} md={4} paddingLeft="50px">
                <img src={imageUrl} alt="support-thumbnail" height="350px" />
              </Grid>
            </>
            :
            <>
              <Grid item xs={4} sm={4} md={6}>
                <Typography variant="h3" paddingBottom='20px' paddingTop='30px'>
                  {title}
                </Typography>
                <Typography>{description}</Typography>
                <Typography>For any support or inquiries, please feel free to reach out to us.</Typography>
                {children}
              </Grid>
              <img src={imageUrl} paddingLeft="10px" alt="support-thumbnail" height="200px" />
            </>
          }
        </Grid>
      </Box>
    </section>
    <Contact/>
    </>
  );
};

export default SupportPageComponent;
