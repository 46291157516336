import React from 'react';
import ProjectNavBar from '../NavBar/ProjectNavBar';
import { Box, Typography, Grid } from "@mui/material";
import theme from "../../styles/theme";
import Contact from '../../pages/Contact/Contact';

const GenericMarketingPage = ({
  projectName,
  tagline,
  description,
  appStoreLink,
  screenshotPath,
  features,
  whyDescription,
}) => {
  return (
    <>
      {/* NavBar Component */}
      <ProjectNavBar projectName={projectName} />

      {/* Main Section */}
      <section
        id={`about${projectName}`}
        theme={theme}
        style={{
          backgroundColor: theme.palette.background.primary,
          paddingTop: '30px',
        }}
      >
        <Box padding="40px">
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Grid container alignItems="flex-end" spacing={4}>
                <Grid item>
                  <Typography
                    variant="h3"
                    paddingTop="20px"
                    style={{ fontFamily: 'Roboto', fontWeight: "Bold" }}
                  >
                    {projectName}
                  </Typography>
                </Grid>
                {appStoreLink && (
                  <Grid item paddingBottom="5px">
                    <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                      <img
                        src="app-store-badge.svg"
                        alt="Download on the App Store"
                        style={{ height: '40px' }}
                      />
                    </a>
                  </Grid>
                )}
              </Grid>
              <Typography
                variant="h5"
                paddingTop="10px"
                style={{ fontFamily: 'Roboto', color: "gray" }}
              >
                {tagline}
              </Typography>
              {/* Render Description as Paragraphs */}
              <Box paddingTop="10px">
                {description.map((paragraph, index) => (
                  <Typography key={index} paddingTop="10px">
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Grid>
            {/* App Screenshot */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <img
                src={process.env.PUBLIC_URL + screenshotPath}
                alt={`${projectName} Screenshot`}
                height="600px"
                style={{ objectFit: 'cover', borderRadius: '10px' }}
              />
            </Grid>
          </Grid>
        </Box>
      </section>

      {/* Features Section */}
      <section
        id="features"
        theme={theme}
        style={{
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Box padding="40px">
          <Typography variant="h4" paddingBottom="20px">
            Key Features
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  {feature.title}
                </Typography>
                <Typography>
                  {feature.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </section>

      {/* Why Section */}
      <section
        id="why"
        theme={theme}
        style={{
          backgroundColor: theme.palette.background.primary,
        }}
      >
        <Box padding="40px">
          <Typography variant="h4" paddingBottom="20px">
            Why {projectName}?
          </Typography>
          <Typography>
            {whyDescription}
          </Typography>
        </Box>
      </section>

      <Contact />
    </>
  );
};

export default GenericMarketingPage;
