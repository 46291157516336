import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Evalexpr()
{
  const id = "evalexpr";

  const thumbnail = "projects/evalexpr/evalexpr-thumbnail.png";

  const title = "Evalexpr";

  const company = "EPITA";

  const shortDescription = "A program that reads and evaluates arithmetic expressions."

  const description = "evalexpr is a program that reads on the standard input an arithmetic expression and writes the result" +
                      "of that expression on the standard output. The input will be of arbitrary size and can be given in" +
                      "reverse polish notation (RPN) or standard (infix) notation. \n\n" +
                      "A basic example in infix notation:\n" +
                      "$ echo \"1 + 1 \" | ./evalexpr\n" +
                      "2\n\n" +
                      "A basic example in infix notation:\n" +
                      "$echo \"5 2 2 ^ 3 + *\" | ./evalexpr -rpn | cat -e" +
                      "35$";

  const technologies = "C";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = null;

  const dates = [
      "October 14, 2022",
      "October 15, 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}