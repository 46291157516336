import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Tiger()
{
  const id = "Tiger";

  const thumbnail = "projects/tiger/tiger-thumbnail.png";

  const title = "Tiger";

  const company = "EPITA";

  const shortDescription = "Implementation of a compiler for the language Tiger.";

  const description = "The goal of the project was to write a compiler for the language Tiger.\n" +
                      "Tiger is derived from a language introduced by Andrew Appel in his book Modern Compiler " + 
                      "Implementation. This document is by no means sufficient to produce an actual Tiger compiler," + 
                       " nor to understand compilation.";

  const technologies = "C++, Flex, Bison";

  const maintechno = ProjectType.CPP;

  const pictures = null;

  const authors = [
    {
      name: "Barbora Plašovská (project leader)",
      link: null
    },
    {
      name: "Enrique Tarabay",
      link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
    },
    {
      name: "Elise Baturone",
      link: "https://www.linkedin.com/in/elise-baturone/"
    },
    {
      name: "Thomas Van Der Perre",
      link: "https://www.linkedin.com/in/thomas-van-der-perre-976b9a213/"
    }
  ];

  const dates = [
      "March 13, 2023",
      "May 31, 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}