import React from 'react';
import GenericPrivacyPolicy from '../../../components/Apps/GenericPrivacyPolicy';

const BeeBetterPrivacyPolicy = () => {
  const sections = [
    {
      title: "What information does the Application obtain and how is it used?",
      content: `The Application does not obtain any personal information when you download and use it. 
                The app allows you to create and track habits, set reminders, and review your progress. 
                All data entered by you is stored locally on your device or on iCloud, depending on your settings. 
                This data is not shared with any third parties and is only used for your personal use within the app. 
                No data is collected for tracking or advertising purposes.`
    },
    {
      title: "Does the Application collect precise real-time location information of the device?",
      content: "This Application does not collect precise information about the location of your mobile device."
    },
    {
      title: "Do third parties see and/or have access to information obtained by the Application?",
      content: "Since the Application does not collect any personal information or track your activities, no data is shared with third parties. All data is stored locally on your device or on iCloud for your personal use."
    },
    {
      title: "What are my opt-out rights?",
      content: "You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes available as part of your mobile device or via the mobile application marketplace or network."
    },
    {
      title: "Children",
      content: `The Application is not used to knowingly solicit data from or market to children under the age of 13.
                The Service Provider does not knowingly collect personally identifiable information from children.
                The Service Provider encourages all children to never submit any personally identifiable information through the Application and/or Services.
                If you have reason to believe that a child has provided personally identifiable information to the Service Provider, please contact the Service Provider (bplasovska@gmail.com) so that they can take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).`
    },
    {
      title: "Security",
      content: "The Service Provider is concerned about safeguarding the confidentiality of your information. Since all data is stored locally on your device or iCloud, there is no risk of your data being accessed by unauthorized third parties. However, please ensure you use strong security measures such as a passcode or biometric authentication to protect your device and iCloud account."
    },
    {
      title: "Changes",
      content: `This Privacy Policy may be updated from time to time. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new version. 
                You are advised to consult this Privacy Policy regularly for any changes. If there are significant changes to the data collection practices, you will be notified accordingly.`
    },
    {
      title: "Your Consent",
      content: "By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by the Service Provider. All data entered into the app, such as habit names, progress, and reminders, is stored locally on your device or on iCloud. No personal information is tracked, collected, or used for any advertising purposes."
    }
  ];

  return (
      <GenericPrivacyPolicy 
        projectName="BeeBetter" 
        sections={sections} 
        contactEmail="bplasovska@gmail.com" 
        effectiveDate="02/04/2024" 
      />
  );
};

export default BeeBetterPrivacyPolicy;
