import React from 'react';
import { Typography, Grid } from "@mui/material";
import theme from "../styles/theme";
import NotFoundNavBar from "../components/NavBar/NotFoundNavBar";

const NotFoundPage = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NotFoundNavBar /> 

      {/* Content Container */}
      <section 
            id = "hero"
            theme={theme}
            style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems:'center',
                      justifyItems: 'center',
                      textAlign: 'center',
                      flexGrow: 1}}
                     >
      <Grid container spacing={2} justifyContent="center" textAlign="center" style={{ marginTop: "200px" }}>
        <Grid item xs={12}>
          <Typography variant="h1" style={{ fontWeight: "bold", fontSize: "4rem", color: theme.palette.text.primary }}>
            404
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: theme.palette.text.secondary, marginBottom: "20px", marginTop: "20px" }}>
            Oops! The page you're looking for doesn't exist.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: theme.palette.text.secondary, marginBottom: "40px" }}>
            It might have been moved, renamed, or maybe it never existed.
          </Typography>
        </Grid>
      </Grid>
      </section>
    </>
  );
};

export default NotFoundPage;
