import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Jaguar()
{
  const id = "jaguar";

  const thumbnail = "projects/jaguar/jaguar-thumbnail.png";

  const title = "Jaguar";

  const company = "EPITA";

  const shortDescription = "Jaguar is an IDE designed specifically " +
                           "for Tiger and Java programming languages.";

  const description = "Jaguar is an integrated development environment (IDE) designed specifically for Tiger " + 
                      "and Java programming languages. It provides a comprehensive toolset to streamline your " + 
                      "software development workflow and enhance your coding experience." +
                      "\n\n" + 
                      "I worked on the frontend of the application using Flutter & Dart, while the rest of " +
                      "our team did the compilation of Tiger & Java.";

  const technologies = "Dart, Flutter";

  const maintechno = ProjectType.Flutter;

  const pictures = [
    "projects/jaguar/jaguar1.png",
    "projects/jaguar/jaguar2.png",
    "projects/jaguar/jaguar3.png",
    "projects/jaguar/jaguar4.png",
    "projects/jaguar/jaguar5.png",
    "projects/jaguar/jaguar6.png",
    "projects/jaguar/jaguar7.png",
    ];

  const sourceCode = "https://github.com/barboraplasovska/jaguar";

  const authors = [
                {
                  name: "Barbora Plašovská",
                  link: null
                },
                {
                  name: "Luc Mahoux",
                  link: "https://www.linkedin.com/in/luc-mahoux/"
                },
                {
                  name: "Charles Neyrand",
                  link: "https://www.linkedin.com/in/charles-neyrand-735671220/"
                },
                {
                  name: "Arnaud Lecoq",
                  link: "https://www.linkedin.com/in/arnaud-lecoq/"
                },
                {
                   name: "Alexandra Delin",
                   link: "https://www.linkedin.com/in/alexandra-delin-39b074251/"
                }
  ];

  const dates = [
      "June 2023",
      "July 2023"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        sourceCode={sourceCode}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}