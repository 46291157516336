import React from 'react';
import { Typography, Box, Backdrop, Container, IconButton, Link } from '@mui/material';
import theme from "../../styles/theme";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {Card, CardActionArea, CardContent, CardMedia, ImageList, ImageListItem, Grid} from '@mui/material';
import { useMediaQuery } from 'react-responsive';


export const ProjectCard = ({ id, title, maintechno, shortDescription, description, thumbnail, pictures, authors, dates, technologies, company, sourceCode, appStoreLink }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [openProjectDetail, setOpenProjectDetail] = React.useState(false);
    const handleCloseProjectDetail = () => {
      setOpenProjectDetail(false);
      document.body.style.overflow = 'unset';
    };
    const handleOpenProjectDetail = () => {
      setOpenProjectDetail(true);
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
    }
    };

   /* const [openPicture, setOpenPicture] = React.useState(false);
    const handleEnlargePicture = () => {
        if (isMobile)
            return;
        setOpenPicture(true);
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
      };
    const handleClosePicture = () => {
        if (isMobile)
            return;
    setOpenPicture(false);
    document.body.style.overflow = 'unset';
    };
*/
    return (
        <>
            <Card sx=
                {{ 
                maxWidth: 345,
                height: 250,
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                alignContent: 'center',
                textJustify: 'center',
                }}>
                <CardActionArea
                    onClick={handleOpenProjectDetail}>
                        {
                        maintechno ? 
                            <Typography
                                variant="body2"
                                textAlign='center'
                                style={{ 
                                    position: 'absolute', 
                                    top: '5px', 
                                    left: '5px', 
                                    zIndex: 1, 
                                    padding: '5px 8px', 
                                    backgroundColor: `${maintechno.color}33`, // Adding opacity to the background color (20%)
                                    borderRadius: '15px',
                                    border: `2px solid ${maintechno.darkColor}`, // Setting border color to maintechno.color
                                    color: maintechno.darkColor, // Setting text color to maintechno.darkColor
                                    fontWeight: 500
                                }}>
                                {maintechno.name}
                            </Typography>                  
                        : 
                            <Container/>
                        }
                    <CardMedia
                    component="img"
                    width="auto"
                    height="120px"
                    paddingTop="5px"
                    image={thumbnail}
                    alt={id}
                    sx=
                    {{
                        objectFit: "contain"
                    }}
                    />
                    <CardContent>
                        <Typography 
                            variant="h5"
                            component="div"
                            textAlign='center'
                            color="primary">
                                {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            textAlign='center'
                            style={{
                                fontStyle: 'italic',
                                fontSize: 13
                                  }}>
                            {dates != null ? dates[0] + " - " + dates[1] : ""}
                        </Typography>
                        <Typography
                            variant="body2"
                            textAlign='center'
                            color="primary">
                                {shortDescription}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Backdrop
                    sx={{ color: '#000000', 
                            zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openProjectDetail}
                >
                <section
                    id={id}
                    theme={theme}
                    style={{
                        backgroundColor: "#ffffff",
                        height: '100%',
                        width: isMobile ? '100%' : '80%',
                        padding: 20,
                        paddingBottom: 30,
                        overflow: 'auto'
                    }}
                    >
                        <Box
                            align="left"
                            >
                            <IconButton
                                aria-label="back"
                                onClick={handleCloseProjectDetail}
                                color="secondary"
                                size="large">
                                <ArrowBackIcon/>
                            </IconButton>
                        </Box>
                        <Box w="100%"
                            backgroundColor="#ffffff"
                            sx={{
                                borderRadius: "12px",
                                paddingLeft: '40px',
                                paddingRight: '40px',
                                }}>
                            
                            <Grid container
                                  spacing={4}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                  sx={{
                                    display: 'flex',
                                    alignContent:'center',
                                    alignItems: 'center',
                                    paddingBottom: '40px'
                                    }}>
                                <Grid item>
                                    <img
                                    component="img"
                                    width="auto"
                                    height={isMobile ? "85px" : "120px"}
                                    paddingTop="5px"
                                    src={thumbnail}
                                    alt={id}
                                    sx=
                                    {{
                                        objectFit: "contain"
                                    }}
                                    />
                                </Grid>
                                <Grid item>
                                    <>
                                        <Typography style={{fontWeight:"bold"}}
                                                    variant="h3">
                                                        {title}
                                        </Typography>
                                        <Typography
                                                style={{fontStyle: 'italic'}}
                                            >
                                            {company}
                                        </Typography>
                                    </>
                                </Grid>
                                {
                                    !appStoreLink
                                    ?
                                        <Container/>
                                    :
                                    <Grid item>
                                        <>
                                            <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                                                <img src='app-store-badge.svg' alt="Download on the App Store" />
                                            </a>
                                        </>
                                    </Grid>
                                }
                            </Grid>
          
                    {
                    pictures ?
                        <ImageList sx={{
                            overflowX: 'auto'
                        }}
                            rowHeight={isMobile ? 200 : 400}
                            >
                        <ImageListItem 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                    }}>
                            {pictures.map((item) => (
                                <>
                                    <img
                                            src={`${item}?fit=cover&auto=format`}
                                            srcSet={`${item}?fit=cover&auto=format&dpr=2 2x`}
                                            alt={title}
                                            height={isMobile ? 200 : 400}
                                            loading="lazy"
                                            style={{paddingRight: '1em'}}
                                    />
                                </>
                            ))}
                        </ImageListItem>
                    </ImageList>
                    :
                    <Container/>
                    }
                            
                            <Grid container
                                  spacing={8}
                                  columns={2}
                                  sx={{paddingTop: '20px'}}>
                                <Grid item xs={2} md={1} ld={1}>
                                    <Typography
                                    sx={{
                                        whiteSpace: "pre-line"
                                        }}>
                                    {description}
                                </Typography>
                                </Grid>
                                <Grid item xs={2} md={1} ld={1}>
                                    <Container
                                        sx={{
                                            backgroundColor: theme.palette.background.primary,
                                            padding: "20px"
                                        }}>
                                        {
                                            !authors
                                            ?
                                                <Container/>
                                            :
                                            <>
                                                <Typography
                                                    variant="h6"
                                                    style={{fontWeight:"bold"}}>
                                                    Authors:
                                                </Typography>
                                                {authors.map((author) => (
                                                    author.link ?
                                                    <Typography style={{textDecoration: 'none'}}>
                                                        •  <Link href={author.link} target="_blank" underline="hover" color="primary">{author.name} </Link>
                                                    </Typography>
                                                    :
                                                    <Typography style={{textDecoration: 'none'}}>
                                                        •  {author.name}
                                                    </Typography>
                                                ))
                                                }
                                            </>
                                        }
                                        <Typography
                                            variant="h6"
                                            style={{fontWeight:"bold"}}>
                                            Technologies:
                                        </Typography>
                                        <Typography>
                                            {technologies}
                                        </Typography>
                                        {
                                            !sourceCode
                                            ?
                                                <Container/>
                                            :
                                            <>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontWeight:"bold",
                                                        textDecoration: 'none'
                                                        }}>
                                                    {'Source code: '}
                                                    <Link href={sourceCode} underline="hover" color="secondary">
                                                           {'Link' }
                                                    </Link>
                                                </Typography>
                                            </>
                                        }
                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                </section> 
            </Backdrop>
        </>
    );
}