import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Tinyprintf()
{
  const id = "tinyprintf";

  const thumbnail = "projects/tinyprintf/tinyprintf-thumbnail.jpeg";

  const title = "Tinyprintf";

  const company = "EPITA";

  const shortDescription = "A simplified version of the printf function(1). "

  const description = "A simplified version of the printf function(1), written in C in 5 hours.";

  const technologies = "C";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = null;

  const dates = [
      "October 17, 2022",
      "October 17, 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}