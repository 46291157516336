import React from 'react';
import SupportPageComponent from '../../../components/Apps/GenericSupportPage';

const TrackMyShoeSupportPage = () => {
  return (
      <SupportPageComponent
        projectName="Track My Shoe"
        title="Support for Track My Shoe"
        description={[
          "Track your running and walking shoes' mileage to know when it's time for a replacement. With Track My Shoe, you can easily assign shoes to your workouts and track how far you've gone. Never let your shoes reach their limit without knowing it!",
          "Sync your workouts automatically from the Health app, and get notified when it's time to assign shoes for your walk or run. Monitor your shoe's progress and stay safe with optimal footwear. Start tracking your shoes today!"
        ]}
        imageUrl={process.env.PUBLIC_URL + "/projects/trackmyshoe/trackmyshoe-thumbnail.png"}
      />
  );
};

export default TrackMyShoeSupportPage;