import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function StitchCounter()
{
  const id = "beebetter";

  const thumbnail = "projects/beebetter/beebetter-thumbnail.png";

  const title = "BeeBetter";

  const company = "";

  const shortDescription = "Habit tracker app made using SwiftUI, data saved using CoreData synced on iCloud using CloudKit."

  const description = "BeeBetter is a habit-tracking app developed with SwiftUI and CoreData, seamlessly synced across Apple " +
                      "devices via iCloud (CloudKit). Users can effortlessly create and monitor daily habits like exercise or " + 
                      "meditation with customizable notifications and visual progress tracking. The app prioritizes user privacy, " + 
                      "storing all data locally on the device, ensuring security without tracking any user data externally. ";

  const technologies = "SwiftUI, CoreData, CloudKit";

  const maintechno = ProjectType.iOS;

  const sourceCode = null;

  const appStoreLink = "https://apps.apple.com/app/id6502307654";

  const pictures = [
    "projects/beebetter/beebetter1.png",
    "projects/beebetter/beebetter2.png",
    "projects/beebetter/beebetter3.png",
    "projects/beebetter/beebetter4.png",
    "projects/beebetter/beebetter5.png",
    "projects/beebetter/beebetter6.png",
    "projects/beebetter/beebetter7.png",
  ];

  const authors = null;

  const dates = null;


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        sourceCode={sourceCode}
        appStoreLink={appStoreLink}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}