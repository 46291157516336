import React from 'react';
import GenericMarketingPage from '../../../components/Apps/GenericMarketingPage';

const TrackMyShoeMarketingPage = () => {
  return (
    <GenericMarketingPage
      projectName="Track My Shoe"
      tagline="Know when to replace shoes."
      description={[
        "Track your running and walking shoes' mileage to know when it's time for a replacement. With Track My Shoe, you can easily assign shoes to your workouts and track how far you've gone. Never let your shoes reach their limit without knowing it!",
        "Sync your workouts automatically from the Health app, and get notified when it's time to assign shoes for your walk or run. Monitor your shoe's progress and stay safe with optimal footwear. Start tracking your shoes today!"
      ]}
      appStoreLink="https://apps.apple.com/app/id6738585430"
      screenshotPath="/projects/trackmyshoe/trackmyshoe-phone-screenshot.png"
      downloadButtonText="Download Track My Shoe"
      features={[
        { 
          title: "Automatic Sync", 
          description: "Sync your workouts automatically from the Health app. No manual entry required, just keep your workouts recorded, and Track My Shoe takes care of the rest." 
        },
        { 
          title: "Shoe Mileage Tracker", 
          description: "Keep track of the mileage on each of your shoes. Once you hit your mileage goal, get a notification to remind you it's time for a replacement." 
        },
        { 
          title: "No Ads, One-Time Purchase", 
          description: "Enjoy a completely ad-free experience. Track My Shoe is available for a one-time purchase, so you can enjoy all the features without interruptions." 
        },
      ]}
      whyDescription={`If you're an avid runner or walker, you know that keeping track of your shoe's mileage is essential for preventing injuries. With Track My Shoe, you can easily manage and track the mileage of your shoes, ensuring that you're always wearing the right footwear for your workouts. 
        Whether you're running marathons or simply enjoying a walk, this app is designed to help you optimize your fitness routine by giving you precise control over your footwear.`}
    />
  );
};

export default TrackMyShoeMarketingPage;
