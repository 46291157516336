import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import DrawerComponent from "./DrawerComponent";


function ProjectNavBar({projectName})
{
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const linkProjectName = projectName.toLowerCase().replace(/\s+/g, '');

    const links = [
        { label: 'Privacy Policy', href: `/${linkProjectName}/privacy-policy` },
        { label: 'Support', href: `/${linkProjectName}/support` }
      ];

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
            <Toolbar variant="regular"> 
                <Box sx={{  display: 'flex', alignItems: 'center', flex: 1 }}>
                    <RouterLink to="/" style={{textDecoration: 'none'}}>
                        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" height="50px"/>
                    </RouterLink>

                    <RouterLink to={`/${linkProjectName}`} style={{textDecoration: 'none'}}>
                        <Typography variant="h6" sx={{ marginLeft: 5, color: 'white' }}>
                                    {projectName}
                        </Typography>
                    </RouterLink>
                </Box>
                    {isMobile ? (
                        <DrawerComponent links={links} linkType="router"/>
                    ) : (
                        <>
                        {links.map((link, index) => (
                            <Box key={index} sx={{ marginLeft: 2 }}>
                            <RouterLink to={link.href} style={{ textDecoration: 'none' }}>
                                <Button sx={{ color: '#ffffff', textTransform: 'none', fontSize: '18px' }}>
                                {link.label}
                                </Button>
                            </RouterLink>
                            </Box>
                        ))}
                        </>
                    )}
            </Toolbar>
        </AppBar>
        </Box>
    );
}

export default ProjectNavBar;