import React from 'react';
import GenericPrivacyPolicy from '../../../components/Apps/GenericPrivacyPolicy';

const TrackMyShoePrivacyPolicy = () => {
  const sections = [
    {
      title: "What information does the Application obtain and how is it used?",
      content: `The Application does not obtain any information when you download and use it. 
                However, if you choose to sync workouts from the Health app, the Application will only access basic data 
                such as the date, workout type, and distance. This information is saved locally on your device or in your private iCloud storage, 
                and it is used solely to help track your shoe usage. No other personal data is collected or stored by the Application.`
    },
    {
      title: "Does the Application collect precise real-time location information of the device?",
      content: "This Application does not collect precise information about the location of your mobile device."
    },
    {
      title: "Do third parties see and/or have access to information obtained by the Application?",
      content: `Since the Application does not collect any personal data or share it with third parties, 
                no information obtained through the Application is shared with external entities. 
                The data synced from the Health app (workout date, type, and distance) is stored privately on your device or in your iCloud storage.`
    },
    {
      title: "What are my opt-out rights?",
      content: `You can stop all collection of information by the Application easily by uninstalling it. 
                You may use the standard uninstall processes available on your mobile device or through the mobile application marketplace.
                If you have synced workouts, this data will remain on your device or iCloud, but no additional data will be collected once the app is uninstalled.`
    },
    {
      title: "Children",
      content: `The Application is not used to knowingly solicit data from or market to children under the age of 13. 
                The Service Provider does not knowingly collect personally identifiable information from children. 
                The Service Provider encourages all children to never submit any personally identifiable information through the Application and/or Services. 
                The Service Provider encourages parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Application and/or Services without their permission. 
                If you have reason to believe that a child has provided personally identifiable information to the Service Provider through the Application and/or Services, please contact the Service Provider (bplasovska@gmail.com) so that they can take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).`
    },
    {
      title: "Security",
      content: `The Service Provider is concerned about safeguarding the confidentiality of your information. 
                However, since the Application only stores minimal information (workout date, type, and distance) locally or in iCloud, 
                and no personal data is collected or tracked, there is no risk of unauthorized access to your data.`
    },
    {
      title: "Changes",
      content: `This Privacy Policy may be updated from time to time for any reason. 
                The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new version. 
                You are advised to consult this Privacy Policy regularly for any changes, as continued use of the Application is deemed approval of all changes.`
    },
    {
      title: "Your Consent",
      content: `By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by the Service Provider. 
                If you choose to sync workouts from the Health app, you consent to the Application accessing and storing minimal data (date, workout type, and distance) solely for the purpose of tracking your shoe usage.`
    }
  ];

  return (
      <GenericPrivacyPolicy 
        projectName="Track My Shoe" 
        sections={sections} 
        contactEmail="bplasovska@gmail.com" 
        effectiveDate="02/04/2024" 
      />
  );
};

export default TrackMyShoePrivacyPolicy;
