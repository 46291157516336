import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Malloc()
{
  const id = "malloc";

  const thumbnail = "projects/malloc/malloc-thumbnail.png";

  const title = "Malloc";

  const company = "EPITA";

  const shortDescription = "Re-implementing of the malloc library using the bit bucket implementation."

  const description = "The main purpose of this project was the complete re-implemenation of the malloc library." +
                      "At the end, my project produced a library libmalloc.so that contained the basic version of" +
                      "malloc(3), free(3), calloc(3) and realloc(3). For my implementation, I chose to use the bit" +
                      "buckets as they were more optimised than a simple linked list and easily understandable.";

  const technologies = "C";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = null;

  const dates = [
      "October 24, 2022",
      "October 29, 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}