import { Box, AppBar, Toolbar, Button } from "@mui/material";
import React from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMediaQuery } from 'react-responsive';
import DrawerComponent from "./DrawerComponent";

function HomeNavBar() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const links = [
    { label: 'About', href: '#about' },
    { label: 'Portfolio', href: '#portfolio' },
    { label: 'Contact', href: '#contact' }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar variant="regular">
          <Box sx={{ flex: 1 }}>
            <AnchorLink href='#hero' style={{ textDecoration: 'none' }}>
              <img src="logo.png" alt="logo" height="50px" />
            </AnchorLink>
          </Box>
          {isMobile ? (
            <DrawerComponent links={links} linkType="anchor"/>
          ) : (
            <>
              {links.map((link, index) => (
                <Box key={index} sx={{ marginLeft: 2 }}>
                  <AnchorLink href={link.href} style={{ textDecoration: 'none' }}>
                    <Button sx={{ color: '#ffffff', textTransform: 'none', fontSize: '18px' }}>
                      {link.label}
                    </Button>
                  </AnchorLink>
                </Box>
              ))}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default HomeNavBar;
