import React from 'react';
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import { ProjectType } from "../../../components/ProjectCard/ProjectType";

export default function Httpd()
{
  const id = "project-h";

  const thumbnail = "projects/project-h/project-h-thumbnail.png";

  const title = "Httpd";

  const company = "EPITA";

  const shortDescription = "Implementation our own HTTP server " +
                           "following the HTTP/1.1 specification.";

  const description = "This project consisted of implementing our own HTTP server in C" +
                      "following the HTTP/1.1 specification.\n\n" +
                      "It was separated into 4 main parts: \n" +
                      "• HTTP parsing: Ensure the validity of the request and store the needed information gathered from" + 
                      "the request.\n" + 
                      "• Socket handler: Handle the setup of the sockets and the incoming connections.\n" + 
                      "• Event handler: Reply to an HTTP request using several techniques.\n" +
                      "• Daemon control: Control the daemon and handle the actions asked by the user.\n";

  const technologies = "C, RFC";

  const maintechno = ProjectType.C;

  const pictures = null;

  const authors = [
    {
      name: "Barbora Plašovská (project leader)",
      link: null
    },
    {
      name: "Enrique Tarabay",
      link: "https://www.linkedin.com/in/christopher-enrique-tarabay-56556222a/"
    },
  ];

  const dates = [
      "November 7, 2022",
      "November 19, 2022"
  ];


    return (
      <ProjectCard
        id={id}
        thumbnail={thumbnail}
        maintechno={maintechno}
        title={title}
        company={company}
        shortDescription={shortDescription}
        description={description}
        technologies={technologies}
        pictures={pictures}
        authors={authors}
        dates={dates}
      />
    );
}