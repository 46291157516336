import React from 'react';

import HomeNavBar from '../components/NavBar/HomeNavBar.js';

import Hero from './Hero/Hero';
import About from './About/About';
import Portfolio from './Portfolio/Portfolio';
import Contact from './Contact/Contact';

export default  function Home() {
  return (
      <>
          <HomeNavBar/>
          <Hero/>
          <About/>
          <Portfolio/>
          <Contact/>
      </>
  );
}